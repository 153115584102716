import React from "react";
import MyContext from "./TodoContext";
import { useContext } from "react";


function TodoListItem({listItem, index}) {

    const [removeItemHandler, dispatch] = useContext(MyContext);

    function changeClass(){
        console.log('listItem.id =' + listItem.id)
        dispatch({type: 'closeItem', itemId: listItem.id})
    }

    function editText() {
        const newText = prompt("What edits do you want to make to the current ToDo Item?", listItem.name)
        dispatch({type:'edit', id: listItem.id, payload: newText})
    }

    function incrementIndex() {
        let newIndex = index + 1
        dispatch({type: 'changeIndex', fromIndex: index, toIndex: newIndex})
    }

    function decrementIndex() {
        if(index === 0){
            return;
        }
        let newIndex = index - 1
        dispatch({type: 'changeIndex', fromIndex: index, toIndex: newIndex})
    }



    return(
        <li  className={'listItem ' + listItem.progress}  data-testid='todoListItemLI'>
            <button value={listItem.id} onClick={() => {removeItemHandler(listItem.id)}}>Delete</button> 
            <button onClick={() => {editText()}} >Edit</button>
            <span className="increment">
                <span onClick={() => incrementIndex()}>&#9195;</span>
                <span onClick={() => decrementIndex()}>&#9196;</span>
            </span>
            <span className="liItem" onClick={() => {changeClass()}}>{index + 1} - {listItem.name}</span> 
            
        </li>
        // <ListItem progress={listItem.progress} className='listItem' data-testid='todoListItemLI'>{listItem.name} <button value={listItem.id} onClick={() => {removeItemHandler(listItem.id)}}>Delete</button></ListItem>
    )
}

export default TodoListItem;