import '../styles/App.css';
import { useEffect, useState } from 'react';
import TodoForm from './partials/TodoForm'
import TitleHeading from './partials/TitleHeading';

function App() {

  const [name, setName] = useState('Slim Shady');

  // useEffect(() => {
  //   let todoListName = prompt("What is this list for?");
  //   if(todoListName !== ''){
  //     setName(todoListName)
  //   }
  // }, [])

  return (
    <div className="App">
        <TitleHeading listName={name}/>
        <section className='mainBody'>
          <TodoForm />
        </section>
    </div>
  );
}

export default App;
