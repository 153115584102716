import { useReducer } from 'react';
import TodoListItem from './TodoListItem'
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import MyContext from './TodoContext';


const initialState = {
    item: '',
    list: [],
}

function reducer(state, action) {
    switch(action.type) {
        case 'add':
            return {list: [...state.list, action.payload]};
        case 'update':
            return {...state, item: action.payload};
        case 'reset':
            return {...state, item: ''};
        case 'remove':
            let newArray = state.list.filter(item => item.id !== action.payload)
            return {list: newArray};
        case 'closeItem':
            const currentTodo = state.list.map((item) => {
                const itemCopy = {...item}
                if(item.id === action.itemId && item.progress === 'activeItem'){
                    // console.log('completed item')
                    itemCopy.progress = 'complete';
                } else if(item.id === action.itemId && item.progress === 'complete'){
                    // console.log('completed item')
                    itemCopy.progress = 'activeItem'
                } 
                return itemCopy;
            })
            return {list: currentTodo};
        case 'edit':
            const editTodo = state.list.map((item) => {
                const itemCopy = {...item}
                if(item.id === action.id){
                    // console.log('completed item')
                    itemCopy.name = action.payload;
                } else {
                    console.log("nothing to edit here")
                }
                return itemCopy;
            })
            return {list: editTodo};
        case 'removeAll':
            let removeCompletedArray = state.list.filter(item => item.progress !== 'complete')
            return {list: removeCompletedArray};
        case 'changeIndex':
            let newIndexList = [...state.list];
            let fromIndex = action.fromIndex;
            let toIndex = action.toIndex;
            let currentItem = newIndexList[fromIndex];
            newIndexList.splice(fromIndex, 1);
            newIndexList.splice(toIndex, 0, currentItem)
            console.log(newIndexList)
            return {list: newIndexList};
        default:
            return;
    }   
}

function TodoForm() { 

    const [state, dispatch] = useReducer(reducer, initialState)

    const setInputValue = (e) => {
        dispatch({
            type: 'update',
            payload: e.target.value
        })
    }

    const addItemHandler = (e) => {
        e.preventDefault();
        if(state.item === '' || state.item === ' ' || state.item === undefined){
           console.log("nothing to see here")
        } else {
            const newItem = {'id': uuidv4(), 'name': state.item, 'progress': 'activeItem'} 
            dispatch({type: 'add', payload: newItem})
            dispatch({type: 'reset'})
        }  
    }

    const removeItemHandler = (id) => {
        dispatch({type: 'remove', payload: id})
    }


    return(
        <MyContext.Provider value={[ removeItemHandler, dispatch ]}>
            <form data-testid="todoForm" >
                <label name=''>Enter a Todo</label>
                <input type='text' id='todo' data-testid="todo" itemRef='newItem' value={state.item} onChange={setInputValue}></input>
                <button onClick={addItemHandler} >Add Item</button>
            </form>
            <ul className="todoList">
                <div className='todoListItemWithButton'>
                    {state.list.map((listItem, index) => <TodoListItem key={listItem.id} index={index} listItem={listItem} />)}
                </div>
            </ul>
            <button onClick={() => dispatch({type: 'removeAll'})}>Remove All Completed Tasks</button>
            <h3>ToDo Item Count: {state.list.length}</h3>
            <h3>ToDo Item Count: {(state.list.map((item) => item.progress !== 'complete')).length}</h3>
        </MyContext.Provider>
    )
}

export default TodoForm;