import { Link } from "react-router-dom"

function TitleHeading({listName}){
    return (
      <header className="todo-header">
        <h1 data-testid="titleHeading">{listName}'s TODO List</h1>
        <nav>
          <Link to="/">Home</Link>
          <Link to="/contact">Contact Us</Link>
        </nav>
      </header>
    ) 
  }

export default TitleHeading