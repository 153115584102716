function ContactForm({listName}){
    return (
      <form className="contactForm">
          <label>First Name</label>
          <input type="text"></input>
          <label>Last Name</label>
          <input type="text"></input>
          <label>Email Address:</label>
          <input type="email"></input>
          <button onClick={(e) => {
            e.preventDefault();
            console.log('I filed this in my metal contact bin under my desk');
            
            }} className="contact-button">submit</button>
      </form>
    ) 
  }

export default ContactForm