import '../styles/App.css';
import '../styles/Contact.css';
import TitleHeading from './partials/TitleHeading';
import ContactForm from './partials/ContactForm';

function Contact() {

  return (
    <div className="App">
        <TitleHeading listName={'Slim Shady'}/>
        <section className='mainBody'>
          <h1>This is my Page</h1>
          <p>I be you wish you could find me.</p>
          <ContactForm />
        </section>
    </div>
  );
}

export default Contact;
